<template>
    <div class="delivery-report-page">
        <div class="container-fluid">
            <h1>Raport dostawy {{ deliveryId }}</h1>
            <div v-if="delivery">
                <b-button v-if="delivery.status!=2" variant="success" v-on:click="goDeliveryConfirm(data.item.delivery_id)" class="m-1 d-print-none">Doręczono wszystko</b-button>
                <div>data: <strong>{{ delivery.date }}</strong></div>
                <div>status: <strong>{{ delivery.statusname }}</strong></div>
                <div v-if="delivery.comments">komentarz dostawy: <strong>{{ delivery.comments }}</strong></div>
                <div>kierowca: <strong>{{ delivery.driver.fullname }}</strong></div>
                <div>zamowienie: <router-link :to="{name: 'AdminOrder',  params: { orderId: delivery.schedule.id } }" ><strong>{{ delivery.schedule.id }}</strong></router-link></div>
                <div>klient: <router-link :to="{name: 'AdminUserPreview',  params: { userId: delivery.klient.id } }" >
                                <strong>{{ delivery.klient.fullname }}</strong>
                            </router-link></div>
                <div>odbiorca: <strong>{{ delivery.address.fullname }}</strong></div>
                <div>adres: <strong>{{ delivery.address.postcode }} {{ delivery.address.city }}, {{ delivery.address.street }} {{ delivery.address.number }}</strong></div>
                <div v-if="delivery.address.comment">adres komentarz: {{ delivery.address.comment }}</div>

                <font-awesome-icon v-if="isLoading" :icon="['fas', 'spinner']" class="fa-spin buttonFaSpin" />
                <b-table v-else :items="delivery_dates" :fields="fields" :show-empty="true" class="mt-2" striped  outlined sort-icon-left empty-filtered-text="Nie znaleziono" empty-text="Brak danych">
                </b-table>
            </div>
        </div>
    </div>
</template>

<script>import axios from "axios";
axios.defaults.headers.common['Accept'] = 'application/json';

export default {
    name: 'adminDeliveryReport',
    data() {
        return {
            isLoading: false,
            deliveryId: this.$route.params.deliveryId,
            delivery: null,
            delivery_dates: null,
            fields: [
                { label: 'Product', key: 'product.name', sortable: true },
                { label: 'Ilość', key: 'amount', sortable: true },
                { label: 'Status ', key: 'statusname', sortable: false }
            ]
        }
    },
    computed: {
    },
    mounted(){
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters['auth/token'];
        axios.get('/admin/delivery_report/' + this.deliveryId ).then( (responce) => {
            this.isLoading =  false;
            this.delivery = responce.data.delivery;
            this.delivery_dates = responce.data.delivery_dates;
        }).catch( () => {
            this.isLoading = false;
            this.$bvToast.toast(`Błąd pobierania danych`, {
                title: 'Błąd',
                autoHideDelay: 5000,
                solid: true,
                variant: 'danger',
                appendToast: true,
                toaster: 'b-toaster-top-right'
            })
        });
    },
    methods: {
        goDeliveryConfirm(id) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters['auth/token'];
            axios.get('/admin/delivery_success/' + id ).then( () => {
                this.isLoading =  false;
                this.getDeliveries();
            }).catch( () => {
                this.isLoading = false;
                this.$bvToast.toast(`Błąd pobierania danych`, {
                    title: 'Błąd',
                    autoHideDelay: 5000,
                    solid: true,
                    variant: 'danger',
                    appendToast: true,
                    toaster: 'b-toaster-top-right'
                })
            });
        },
    },
}
</script>
